<template>
  <div class="content-box">
    <div class="content">
      <div class="search-action">
        <div class="inputs_filter">
          <el-input
            placeholder="请输入关键字"
            prefix-icon="el-icon-search"
            class="search-input"
            v-model="search"
            clearable
            @clear="getList"
          >
          </el-input>
          <div class="search-btn" @click="getList">搜索</div>
        </div>
      </div>

      <div class="recommend">
        <div class="recommend-title">
          <span class="big-title">为你推荐</span>
        </div>
        <div class="recommend-outer">
          <ul class="recommend-content">
            <li
              class="recommend-item"
              v-for="(item, index) in List"
              :key="index"
              @click="xq(item.start, item.id)"
            >
              <div class="link-href">
                <div class="top-banner">
                  <img :src="urlImg + item.cover" alt="" class="banner-img" />
                </div>
                <div class="de-topic">
                  {{ item.title }}
                </div>
                <div class="de-status-box">
                  <span class="lf-time"
                    ><i class="el-icon-time"></i
                    ><span class="center-time"
                      >{{ item.start_time | formatDate }}
                    </span></span
                  >
                  <span
                    class="apply-btn"
                    :class="item.start == '未开始'  ? 'apply-btn1' : ''"
                    :style="item.start == '进行中' ? 'background:#28D094' : ''"
                  >
                    {{ item.start }}</span
                  >
                </div>
              </div>
            </li>
          </ul>
        </div>
        <!-- 分页 -->
        <div class="pagination">
          <pagination
            class="pages"
            v-show="total > 0"
            :total="total"
            layout="prev, pager, next"
            :page.sync="listQuery.page"
            :limit.sync="listQuery.limit"
            @pagination="getList"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { activityList } from "../../../common/js/api";
import { formatDate } from "../../../common/js/times"; //时间
import Pagination from "@/common/Pagination";
import { urlImg } from "@/common/js/url";

export default {
  components: { Pagination },
  data() {
    return {
      List: [],
      total: 0, //总条目数
      start: "",
      listQuery: {
        limit: 6,
        page: 1,
      },
      search: "",
      urlImg: "",
    };
  },
  filters: {
    formatDate(time) {
      time = time * 1000;
      let date = new Date(time);
      return formatDate(date, "yyyy-MM-dd");
    },
  },
  created() {
    this.search = this.$route.query.keyword;
    this.getList();
    this.urlImg = urlImg();
  },
  methods: {
    // 跳转详情
    xq(item, id) {
       this.$router.push({
        path: "/member8/XQ",
        query: {
          id: id,
          
        },
      });
    },
    // 获取科创活动列表
    getList() {
      activityList({
        title: this.search,
        page: this.listQuery.page,
        limits: this.listQuery.limit,
      })
        .then((res) => {
          this.List = res.data.data;
          this.total = res.data.total;

          var myDate = new Date();
          var s = myDate.toLocaleDateString();
          var d = new Date(s);
          var time2 = d.valueOf() / 1000;
          //  console.log(time2);

          for (let i = 0; i < this.List.length; i++) {
            if (this.List[i].start_time > time2) {
              this.List[i].start = "未开始";
            } else if (
              this.List[i].start_time < time2 &&
              time2 < this.List[i].end_time
            ) {
              this.List[i].start = "进行中";
            } else if (this.List[i].end_time < time2) {
              this.List[i].start = "已结束";
            }
          }
        })
        .catch(() => {});
    },
  },
};
</script>

<style lang="less" scoped>
.content {
  .search-action {
     margin: 20px 30% 16px 20%;
  }
  /deep/.el-input__inner {
    border: 0 ;
}
  .recommend {
    .recommend-outer {
      .recommend-content {
        width: 100%;
        margin: 0 auto;
        display: flex;
        flex-wrap: wrap;
        padding-bottom: 14px;
        padding-top: 13px;
        .recommend-item:nth-child(3n + 0) {
          margin-right: 0;
        }
         .recommend-item:hover {
             
             transform: scale(1.05);
          }
        .recommend-item {
          position: relative;
          margin-top: 20px;
          width: calc(33.3% - 15px);
          height: 300px;
          margin-right: 20px;
          background: #fff;
          border-radius: 3px;
          cursor: pointer;
          font-size: 14px;
          box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.4);
          .top-banner {
            height: 200px;
          }
          .de-topic {
            padding: 0 15px;
            margin-top: 14px;
            font-size: 16px;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
            overflow: hidden;
            color: #00050a;
            line-height: 1.5;
          }
          .de-status-box {
            position: absolute;
            left: 0;
            right: 0;
            bottom: 0;
            height: 34px;
            justify-content: space-between;
            color: #b7b5b5;
            padding: 0 16px 0 20px;
            background-color: #f4f4f4;
          }
          .de-status-box,
          .lf-time {
            display: flex;
            align-items: center;
            .center-time {
              margin: 0 10px 0 15px;
            }
            .apply-btn {
              height: 24px;
              line-height: 24px;
              width: 61px;
              background: #ff7272;
              border-radius: 5px;
              color: #fff;
              display: flex;
              align-items: center;
              justify-content: center;
            }
            .apply-btn1 {
              background: #bbb;
            }
            .apply-btn2 {
              background: rgb(138, 220, 8);
            }
          }
        }
      }
    }
    .pagination {
      text-align: center;
    }
  }
}
</style>